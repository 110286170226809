/* eslint-disable */
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

export default {
  props: {
    fields: Object,
    items: Array,
  },
  data: () => ({
    json_fields: [],
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),

  methods: {
    getData() {
      return this.items;
    },

    getFields() {
      return this.fields;
    },
  },
};
