import { useAppStore } from "../stores/main";
import Setting from "../../setting.js";
import SmartTableRequests from "@/components/SmartTableRequests.vue";
import ExcelExport from "@/components/ExcelExport.vue";
// import moment from "moment";

export default {
  name: "UsersView",
  components: {
    SmartTableRequests,
    ExcelExport,
  },
  data: () => ({
    store: useAppStore(),
    loading: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Requests",
        disabled: false,
      },
    ],
    items: [],

    statuses: [
      {
        id: 1,
        name: "CREATED",
      },

      {
        id: 2,
        name: "APPROVED",
      },
      {
        id: 3,
        name: "UPDATED",
      },
      {
        id: 4,
        name: "ARCHIVED",
      },
    ],

    validityItems: [
      {
        id: "1",
        name: "Current",
      },
      { id: "2", name: "Updated" },
      { id: "3", name: "Historical" },
    ],
    announcementItems: [
      {
        id: "1",
        name: "Testing",
        description:
          "refers to testing activities which are not part of an O-RAN PlugFest (note: to avoid duplication, because PlugFest information is included in another layer of the map)",
      },
      { id: "2", name: "Field Trial", description: "outdoor trials" },
      {
        id: "3",
        name: "Deployment",
        description:
          "commercial deployment planned or in progress but not launched",
      },
      {
        id: "4",
        name: "Pre-Commercial Launch",
        description:
          "friendly users using the pre-commercial network but not open to the public to subscribe",
      },
      {
        id: "5",
        name: "Commercial Launch",
        description: "commercial service offered to members of the public",
      },
      { id: "6", name: "MoU", description: "Memorandum of Understanding" },
    ],

    template: {
      id: "",
      data: {
        email: "",
      },
    },

    fields: {
      Id: "id",
      Source: "source",
      Name: "name",
      Company: "company",
      Email: "email",
      Created: "created",
      Updated: "updated",
      Status: "status",
      Operators: "operators",
      ValidityOfEntry: "validityOfEntry",
      AnnouncementType: "announcementType",
      AnnouncementDate: "announcementDate",
      HistoricalDate: "historicalDate",
      Type: "type",
      RegionName: "regionName",
      RegionCode: "regionCode",
      CountryName: "countryName",
      CountryCode: "countryCode",
      Latitude: "latitude",
      Longitude: "longitude",
      Specifics: "specifics",
      NetworkConfiguration: "networkConfiguration",
      Weblink: "weblink",
      SubmiterNote: "submiterNote",
      ApproverNote: "approverNote",
    },

    exportItems: [],

    headers: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Source",
        align: "start",
        sortable: true,
        value: "data.status.source",
      },
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "data.submiter.name",
      },
      {
        text: "Company",
        align: "start",
        sortable: true,
        value: "data.submiter.company",
      },
      {
        text: "Email",
        align: "start",
        sortable: true,
        value: "data.submiter.email",
      },
      {
        text: "Created",
        align: "start",
        sortable: true,
        value: "data.status.created",
      },
      {
        text: "Updated",
        align: "start",
        sortable: true,
        value: "data.status.updated",
      },
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "data.status.approveStatus",
      },
      {
        text: "Edited entry",
        align: "start",
        sortable: false,
        value: "data",
      },
      {
        text: "Current entry",
        align: "start",
        sortable: false,
        value: "source",
      },
      {
        text: "Submiter note",
        align: "start",
        sortable: false,
        value: "data.submiter.notes",
      },
      {
        text: "Approver note",
        align: "start",
        sortable: false,
        value: "data.status.approverNote",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  async mounted() {
    // console.warn("mounted");

    if (!this.store.getAuth) {
      this.$router.push("/");
    }

    this.loading = true;
    const result = await this.getAllRequests();

    this.items = result.data.data;

    this.exportItems = this.items.map((x) => {
      // console.warn(x);

      return {
        name: x.data.submiter.name,
        email: x.data.submiter.email,
        id: x.id,
        source: x.data.status.source,
        company: x.data.submiter.company,
        created: x.data.status.created,
        updated: x.data.status.updated,
        status: this.statuses.find((y) => y.id === x.data.status.approveStatus)
          .name,
        operators: x.data.operators
          .map((y) => {
            return y.name;
          })
          .join("; "),
        validityOfEntry: this.validityItems.find(
          (y) => y.id === x.data.validity.id
        ).name,
        announcementType: this.announcementItems.find(
          (y) => y.id === x.data.announcementType.id
        ).name,
        announcementDate: x.data.announcementDate,
        historicalDate: x.data.historicalDate,
        type: x.data.map.type,
        regionName: x.data.map.regions ? x.data.map.regions[0].name : null,
        regionCode: x.data.map.regions ? x.data.map.regions[0].code : null,
        countryName: x.data.map.countries ? x.data.map.countries[0].name : null,
        countryCode: x.data.map.countries ? x.data.map.countries[0].code : null,
        latitude: this.getCoordinates(x.data.map, "Lat"),
        longitude: this.getCoordinates(x.data.map, "Lon"),
        specifics: x.data.specifics,
        networkConfiguration: x.data.configuration,
        weblink: x.data.weblink,
        submiterNote: x.data.submiter.notes,
        approverNote: x.data.status.approverNote,
      };
    });

    /*
    console.warn({
      mountedItems: this.items,
      exportItems: this.exportItems,
    });
    */

    this.loading = false;
  },
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },

    getCoordinates(map, type) {
      switch (map.type) {
        case "Point":
          if (type == "Lat") return map.gps.lat;
          if (type == "Lon") return map.gps.lon;

          break;
        case "Region":
          if (type == "Lat") return map.regions[0].gps.lat;
          if (type == "Lon") return map.regions[0].gps.lon;

          break;
        case "Country":
          if (type == "Lat") return map.countries[0].gps.lat;
          if (type == "Lon") return map.countries[0].gps.lon;

          break;
        default:
          break;
      }

      return null;
    },

    async getAllRequests() {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Externals/GetAllExternals",
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },

    async onUpdate(event) {
      console.warn({
        onUpdate: event,
      });

      await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Externals/AdminUpdateExternal",
        event.data,
        {
          // withCredentials: true,
        }
      );

      this.loading = true;
      const result = await this.getAllRequests();
      this.loading = false;
      this.items = result.data.data;
    },

    /*
    async onInsert(event) {
      console.warn({
        onInsert: event,
      });
    },
    */
  },
};
