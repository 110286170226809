import { useAppStore } from "../stores/main";

export default {
  name: "HomeView",
  components: {},
  data: () => ({
    store: useAppStore(),
    password: null,
    secret: "P8NAtTc",
    isPassValid: false,
  }),
  async mounted() {},
  methods: {
    onLogin() {
      this.isPassValid = true;

      if (this.password == this.secret) {
        this.store.saveAuth(true);
      } else {
        this.isPassValid = false;
      }
    },
    onMapRequests() {
      this.$router.push("/map-requests");
    },
    onMapData() {
      this.$router.push("/trials");
    },
  },
};
