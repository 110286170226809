import moment from "moment";
import ExcelExport from "@/components/ExcelExport.vue";

export default {
  name: "SmartTableRequests",
  components: {
    ExcelExport,
  },
  props: {
    headers: Array,
    items: Array,
    fields: Object,
    editedItem: Object,
    loading: Boolean,
  },
  data() {
    return {
      search: "",
      dialog: false,
      editedIndex: -1,

      editedItemInner: JSON.parse(JSON.stringify(this.editedItem)),
      currentItemInner: JSON.parse(JSON.stringify(this.editedItem)),
      defaultItemInner: JSON.parse(JSON.stringify(this.editedItem)),

      menu: false,
      modal: false,

      statuses: [
        {
          id: 1,
          name: "CREATED",
        },

        {
          id: 2,
          name: "APPROVED",
        },
        {
          id: 3,
          name: "UPDATED",
        },
        {
          id: 4,
          name: "ARCHIVED",
        },
      ],

      validityItems: [
        {
          id: "1",
          name: "Current",
        },
        { id: "2", name: "Updated" },
        { id: "3", name: "Historical" },
      ],
      announcementItems: [
        {
          id: "1",
          name: "Testing",
          description:
            "refers to testing activities which are not part of an O-RAN PlugFest (note: to avoid duplication, because PlugFest information is included in another layer of the map)",
        },
        { id: "2", name: "Field Trial", description: "outdoor trials" },
        {
          id: "3",
          name: "Deployment",
          description:
            "commercial deployment planned or in progress but not launched",
        },
        {
          id: "4",
          name: "Pre-Commercial Launch",
          description:
            "friendly users using the pre-commercial network but not open to the public to subscribe",
        },
        {
          id: "5",
          name: "Commercial Launch",
          description: "commercial service offered to members of the public",
        },
        { id: "6", name: "MoU", description: "Memorandum of Understanding" },
      ],
    };
  },

  computed: {
    getLanguage() {
      // console.warn(this.editedItemInner.data.country);
      return this.editedItemInner.data.country;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    getExcelItem(item) {
      /*
      console.warn({
        getExcelItem: this.items,
      });
      */

      return this.items
        .filter((x) => x.id == item.id)
        .map((x) => {
          // console.warn(x);

          return {
            name: x.data.submiter.name,
            email: x.data.submiter.email,
            id: x.id,
            source: x.data.status.source,
            company: x.data.submiter.company,
            created: x.data.status.created,
            updated: x.data.status.updated,
            status: this.statuses.find(
              (y) => y.id === x.data.status.approveStatus
            ).name,
            operators: x.data.operators
              .map((y) => {
                return y.name;
              })
              .join("; "),
            validityOfEntry: x.data.validity
              ? this.validityItems.find((y) => y.id === x.data.validity.id).name
              : null,
            announcementType: x.data.announcementType
              ? this.announcementItems.find(
                  (y) => y.id === x.data.announcementType.id
                ).name
              : null,
            announcementDate: x.data.announcementDate,
            historicalDate: x.data.historicalDate,
            type: x.data.map.type,
            regionName: x.data.map.regions ? x.data.map.regions[0].name : null,
            regionCode: x.data.map.regions ? x.data.map.regions[0].code : null,
            countryName: x.data.map.countries
              ? x.data.map.countries[0].name
              : null,
            countryCode: x.data.map.countries
              ? x.data.map.countries[0].code
              : null,
            latitude: this.getCoordinates(x.data.map, "Lat"),
            longitude: this.getCoordinates(x.data.map, "Lon"),
            specifics: x.data.specifics,
            networkConfiguration: x.data.configuration,
            weblink: x.data.weblink,
            submiterNote: x.data.submiter.notes,
            approverNote: x.data.status.approverNote,
          };
        });
    },
    getCoordinates(map, type) {
      switch (map.type) {
        case "Point":
          if (type == "Lat") return map.gps.lat;
          if (type == "Lon") return map.gps.lon;

          break;
        case "Region":
          if (type == "Lat") return map.regions[0].gps.lat;
          if (type == "Lon") return map.regions[0].gps.lon;

          break;
        case "Country":
          if (type == "Lat") return map.countries[0].gps.lat;
          if (type == "Lon") return map.countries[0].gps.lon;

          break;
        default:
          break;
      }

      return null;
    },
    initialize() {},
    editItem(item) {
      /*
      console.warn({
        editItem: item,
      });
      */

      this.editedIndex = this.items.indexOf(item);
      this.editedItemInner = JSON.parse(JSON.stringify(item)); // Object.assign({}, item);
      this.currentItemInner = JSON.parse(JSON.stringify(item)); // Object.assign({}, item);

      /*
      console.warn({
        editItem: item,
        editedIndex: this.editedIndex,
        editedItemInner: this.editedItemInner,
      });
      */

      /* prettier-ignore */
      if (this.editedItemInner.data.announcementType) {
        this.editedItemInner.data.announcementType = this.announcementItems.find(
          (x) => x.id === this.editedItemInner.data.announcementType.id
        );
      }

      /* prettier-ignore */
      if (this.editedItemInner.data.validity) {
          this.editedItemInner.data.validity = this.validityItems.find(
            (x) => x.id === this.editedItemInner.data.validity.id
          );
      }

      this.editedItemInner.data.historicalDate = moment(
        this.editedItemInner.data.historicalDate
      ).format("YYYY-MM-DD");

      this.editedItemInner.data.announcementDate = moment(
        this.editedItemInner.data.announcementDate
      ).format("YYYY-MM-DD");

      this.editedItemInner.data.operators = this.editedItemInner.data.operators
        .map((x) => x.name)
        .join(", ");

      if (this.currentItemInner.source) {
        /* prettier-ignore */
        if (this.currentItemInner.source.announcementType) {
        this.currentItemInner.source.announcementType = this.announcementItems.find(
          (x) => x.id === this.currentItemInner.source.announcementType.id
        );
        }

        /* prettier-ignore */
        if (this.currentItemInner.source.validity) {
          this.currentItemInner.source.validity = this.validityItems.find(
            (x) => x.id === this.currentItemInner.source.validity.id
          );
          }

        this.currentItemInner.source.historicalDate = moment(
          this.currentItemInner.source.historicalDate
        ).format("YYYY-MM-DD");

        this.currentItemInner.source.announcementDate = moment(
          this.currentItemInner.source.announcementDate
        ).format("YYYY-MM-DD");

        /* prettier-ignore */
        this.currentItemInner.source.operators = this.currentItemInner.source.operators
        .map((x) => x.name)
        .join(", ");
      }

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItemInner = Object.assign({}, this.defaultItemInner);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        /*
        if (this.currentItemInner.source == null) {
          this.currentItemInner.source = {
            id: null,
            validity: null,
            announcementType: null,
          };
        }
        */

        if (this.editedItemInner.data.historicalDate == "Invalid date") {
          this.editedItemInner.data.historicalDate = null;
        }

        if (
          this.currentItemInner &&
          this.currentItemInner.source &&
          this.currentItemInner.source.historicalDate == "Invalid date"
        ) {
          this.currentItemInner.source.historicalDate = null;
        }

        this.editedItemInner.data.id = this.editedItemInner.id;

        if (this.currentItemInner.source) {
          this.currentItemInner.source.id = this.editedItemInner.id;
        }

        /*
        console.warn({
          save: {
            editedItemInner: this.editedItemInner,
            currentItemInner:
              this.currentItemInner.source != null
                ? this.currentItemInner.source.validity
                : null,
          },
        });
        */

        /* prettier-ignore */
        if(this.editedItemInner.data.validity) {
        this.editedItemInner.data.validity = this.validityItems.find(
          (x) => x.id === this.editedItemInner.data.validity
        );
        }

        /* prettier-ignore */
        if(this.editedItemInner.data.announcementType) {
        this.editedItemInner.data.announcementType = this.announcementItems.find(
          (x) => x.id === this.editedItemInner.data.announcementType
        );
        }

        /* prettier-ignore */
        this.editedItemInner.data.operators = this.editedItemInner.data.operators.split(
          ","
        ).map((x) => { return {
          id: null,
          name: x.trim()
        }
        });

        /* prettier-ignore */
        if(this.currentItemInner.source && this.currentItemInner.source.validity) {
          if(!this.currentItemInner.source.validity.id) {
            this.currentItemInner.source.validity = this.validityItems.find(
              (x) => x.id === this.currentItemInner.source.validity
            );
          }
          }

        /* prettier-ignore */
        if(this.currentItemInner.source && this.currentItemInner.source.announcementType) {
          if(!this.currentItemInner.source.announcementType.id) {
            this.currentItemInner.source.announcementType = this.announcementItems.find(
              (x) => x.id === this.currentItemInner.source.announcementType.id
            );
          }
          }

        /* prettier-ignore */
        if(this.currentItemInner.source && this.currentItemInner.source.operators) {
          this.currentItemInner.source.operators = this.currentItemInner.source.operators.split(
            ","
          ).map((x) => { return {
            id: null,
            name: x.trim()
          }
          });
        }

        if (
          this.currentItemInner.source != null &&
          this.editedItemInner.data.status.approveStatus == 2
        ) {
          /* prettier-ignore */
          this.currentItemInner.source.submiter = this.editedItemInner.data.submiter;
          /* prettier-ignore */
          this.currentItemInner.source.status = this.editedItemInner.data.status;

          this.$emit("onUpdate", {
            data: this.currentItemInner.source,
          });
        } else {
          this.$emit("onUpdate", {
            data: this.editedItemInner.data,
          });
        }

        Object.assign(this.items[this.editedIndex], this.editedItemInner);
      } else {
        this.$emit("onInsert", {
          data: this.editedItemInner,
        });

        // this.items.push(this.editedItem);
      }
      this.close();
    },
    onMove(obj) {
      /*
      console.warn({
        onMove: obj,
        source: this.currentItemInner.source[obj],
        data: this.editedItemInner.data[obj],
      });
      */

      if (obj == "lat" || obj == "lon") {
        /* prettier-ignore */
        this.currentItemInner.source.map.gps[
            obj
          ] = this.editedItemInner.data.map.gps[obj];
      } else {
        this.currentItemInner.source[obj] = this.editedItemInner.data[obj];
      }
    },
    onMoveCountry(obj) {
      /*
      console.warn({
        onMove: obj,
      });
      */

      if (obj == "name" || obj == "code") {
        /* prettier-ignore */
        this.currentItemInner.source.map.countries[0][
            obj
          ] = this.editedItemInner.data.map.countries[0][obj];
      }

      if (obj == "lat" || obj == "lon") {
        /* prettier-ignore */
        this.currentItemInner.source.map.countries[0].gps[
            obj
          ] = this.editedItemInner.data.map.countries[0].gps[obj];
      }
    },
    onMoveRegion(obj) {
      /*
      console.warn({
        onMove: obj,
      });
      */

      if (obj == "name" || obj == "code") {
        /* prettier-ignore */
        this.currentItemInner.source.map.regions[0][
            obj
          ] = this.editedItemInner.data.map.regions[0][obj];
      }

      if (obj == "lat" || obj == "lon") {
        /* prettier-ignore */
        this.currentItemInner.source.map.regions[0].gps[
            obj
          ] = this.editedItemInner.data.map.regions[0].gps[obj];
      }
    },
    onMoveDifferentMapType() {
      this.currentItemInner.source.map = JSON.parse(
        JSON.stringify(this.editedItemInner.data.map)
      );
    },
    GetArrowColor(LatLon) {
      /*
      console.warn({
        currentItemInner: this.currentItemInner.source,
        editedItemInner: this.editedItemInner.data,
      });
      */

      if (
        this.currentItemInner.source.map.type.toUpperCase() == "POINT" &&
        this.editedItemInner.data.map.type.toUpperCase() == "POINT"
      ) {
        return this.currentItemInner.source.map.gps[LatLon] !=
          this.editedItemInner.data.map.gps[LatLon]
          ? "red"
          : "black";
      }

      return "black";
    },
    GetArrowCountryColor(obj) {
      /*
      console.warn({
        currentItemInner: this.currentItemInner.source,
        editedItemInner: this.editedItemInner.data,
      });
      */

      if (obj == "name" || obj == "code") {
        return this.currentItemInner.source.map.countries[0][obj] !=
          this.editedItemInner.data.map.countries[0][obj]
          ? "red"
          : "black";
      }

      if (obj == "lat" || obj == "lon") {
        return this.currentItemInner.source.map.countries[0].gps[obj] !=
          this.editedItemInner.data.map.countries[0].gps[obj]
          ? "red"
          : "black";
      }
    },
    GetArrowRegionColor(obj) {
      /*
      console.warn({
        currentItemInner: this.currentItemInner.source.map,
        editedItemInner: this.editedItemInner.data.map,
      });
      */

      if (obj == "name" || obj == "code") {
        return this.currentItemInner.source.map.regions[0][obj] !=
          this.editedItemInner.data.map.regions[0][obj]
          ? "red"
          : "black";
      }

      if (obj == "lat" || obj == "lon") {
        return this.currentItemInner.source.map.regions[0].gps[obj] !=
          this.editedItemInner.data.map.regions[0].gps[obj]
          ? "red"
          : "black";
      }
    },

    downloadItem(item) {
      this.$emit("onDownloadItem", {
        data: item,
      });
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return {
            text: "CREATED",
            color: "blue",
          };
        case 2:
          return {
            text: "APPROVED",
            color: "green",
          };
        case 3:
          return {
            text: "UPDATED",
            color: "red",
          };
        default:
          return {
            text: "OTHER",
            color: "grey",
          };
      }
    },
  },
};
