import { defineStore } from "pinia";

export const useAppStore = defineStore({
  id: "appStore",

  state: () => ({
    auth: false,
  }),

  getters: {
    getAuth: (state) => state.auth,
  },

  actions: {
    saveAuth(data) {
      this.auth = data;
    },
  },
});
